import React from "react";

import { Nav } from "../../components";

import { Table, TD, TH } from "./styles";

function dashboard() {
  return (
    <Nav title="Aulas agendadas">
      <div>
        <Table>
          <tr>
            <TH>Usuario</TH>
            <TH>Hora</TH>
            <TH>Assunto</TH>
            <TH>Status</TH>
          </tr>
          {/* <tr>
            <TD>Mateus Lima</TD>
            <TD>01/06 12:30 as 13:00</TD>
            <TD>Ortografia</TD>
            <TD>realizada</TD>
          </tr> */}
        </Table>
      </div>
    </Nav>
  );
}

export default dashboard;
