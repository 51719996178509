import React, { useEffect, useState } from "react";
import {
  getDay,
  addDays,
  format,
  setHours,
  setMinutes,
  formatISO,
  parse,
} from "date-fns";
import { useSelector } from "react-redux";

import { Nav, Button } from "../../../components";
import api from "../../../services/api";

import {
  ItemHeader,
  ItemTime,
  Container,
  Header,
  List,
  ContainerList,
  BadgeStatus,
} from "./styles";

function MyAgenda() {
  const { user } = useSelector((store) => store.user);

  const timeList = [
    "00:00",
    "00:30",
    "01:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  const [listDays, setListDays] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDataApi();
    getListDays();
  }, []);

  const loadDataApi = () => {
    setLoading(true);
    const startDate = format(new Date(), "dd/MM/yyyy");
    const endDate = format(addDays(new Date(), 6), "dd/MM/yyyy");
    api
      .get(
        `agendamento/professor/agenda/${user.id}?dataInicio=${startDate}&dataFim=${endDate}`
      )
      .then((resp) => {
        console.log("data", resp.data);
        setAppointmentList(resp.data.rows);
      })
      .catch((error) => {
        setAppointmentList([]);
        console.log("error", error);
        alert("Não foi possível trazer os dados da sua agenda");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getListDays = () => {
    const list = [];
    for (let i = 0; i < 7; i++) {
      const date = addDays(new Date(), i);
      const formattedDate = format(date, "dd/MM");
      list.push({
        date,
        formattedDate,
        day: getDayWeek(getDay(date)),
      });
    }
    setListDays(list);
  };

  const getDayWeek = (day = 0) => {
    if (day === 0) return "Dom";
    if (day === 1) return "Seg";
    if (day === 2) return "Ter";
    if (day === 3) return "Qua";
    if (day === 4) return "Qui";
    if (day === 5) return "Sex";
    if (day === 6) return "Sab";
  };

  const getEventDay = (hour, date) => {
    return appointmentList.find((item) => {
      let srtDate = item.data.split(" ")[0];
      if (srtDate.length !== 10) {
        srtDate = item.data.substr(0, 10);
      }
      const itemDate = format(
        parse(srtDate, "yyyy-MM-dd", new Date()),
        "dd/MM"
      );
      return itemDate === date && item.hora === hour;
    });
  };

  return (
    <Nav title="Minha Agenda">
      <Container>
        <ContainerList>
          <Header>
            {[{}, ...listDays].map((item, index) => (
              <div key={String(index)}>
                {index === 0 ? (
                  <ItemHeader />
                ) : (
                  <ItemHeader>
                    <label>{item.formattedDate}</label>
                    <label>({item.day})</label>
                  </ItemHeader>
                )}
              </div>
            ))}
          </Header>
          <List>
            {timeList.map((item) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ItemTime time>
                  <label style={{ padding: 10 }}>{item}</label>
                </ItemTime>
                {listDays.map((day) => (
                  <ItemList
                    day={day}
                    item={item}
                    loadList={loadDataApi}
                    eventDay={getEventDay(item, day.formattedDate)}
                  />
                ))}
              </div>
            ))}
          </List>
        </ContainerList>
      </Container>
    </Nav>
  );
}

const ItemList = ({ item, day, loadList = () => {}, eventDay }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (eventDay) {
      console.log("event", eventDay);
    }
  }, [eventDay]);

  const registerAppointmentList = () => {
    let date = day.date;
    let hour = item.split(":")[0];
    let minute = item.split(":")[1];
    date = setHours(date, parseInt(hour));
    date = setMinutes(date, parseInt(minute));
    setLoading(true);
    api
      .post("agendamento/professor/agenda", {
        data: formatISO(date),
        hora: item,
      })
      .then((resp) => {
        console.log("resp", resp.data);
        alert("Dia marcado como disponível");
        loadList();
      })
      .catch((error) => {
        console.log("registerAppointmentList", error);
        alert(
          error?.response?.data?.error ||
            "Não foi possível concluir sua solicitação. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAppointment = () => {
    setLoading(true);
    api
      .delete(`agendamento/professor/agenda/${eventDay.id}`)
      .then((resp) => {
        console.log("resp", resp.data);
        loadList();
        alert("Horário marcado como indisponível");
      })
      .catch((error) => {
        console.log("registerAppointmentList", error);
        alert(
          error?.response?.data?.error ||
            "Não foi possível concluir sua solicitação. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = () => {
    if (eventDay && eventDay.aula.length > 0) {
      alert("Horário já reservado");
    } else if (eventDay) {
      deleteAppointment();
    } else {
      registerAppointmentList();
    }
  };

  return (
    <ItemTime onClick={handleClick}>
      {!loading && (
        <BadgeStatus status={!eventDay ? 0 : eventDay.aula.length > 0 ? 2 : 1}>
          {!eventDay ? (
            <label>indisponível</label>
          ) : eventDay.aula.length > 0 ? (
            <label>reservado</label>
          ) : (
            <label>disponível</label>
          )}
        </BadgeStatus>
      )}
      {loading && <div className="loader" />}
    </ItemTime>
  );
};

export default MyAgenda;
