import styled, { css } from "styled-components";

import { colors } from "../../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  max-height: 60%;
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TitleDate = styled.h2`
  color: ${colors.primary};
`;

export const ContainerItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: white;
  padding-bottom: 10px;
  border-radius: 10px;
  width: 200px;

  img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1;
  }

  #containerButton {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    button {
      margin-top: 10px;
      border: 0px solid transparent;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    text-align: end;
  }
`;
