import styled from "styled-components";

import { colors } from "../../styles";

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`;

export const ItemListMenu = styled.li`
  box-sizing: border-box;
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? "white" : `${colors.primary}`};
  color: ${(props) => (props.selected ? colors.primary : "white")};

  label {
    margin-left: 32px;
  }

  #icon {
    width: 20px;
    height: 20px;
  }

  :hover {
    background-color: white;
    color: ${colors.primary};
  }
`;

export const Menu = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 345px;
  background-color: ${colors.primary};
  height: 100%;
  box-sizing: border-box;

  ul {
    padding-left: 30px;
    margin-right: 30px;
    box-sizing: border-box;
  }
`;

export const MenuHeader = styled.div`
  height: 100px;
  background-color: ${colors.primary};
  display: flex;
  justify-content: flex-start;
  padding-left: 50px;
  padding-top: 50px;
  img {
    height: 50px;
  }
`;

export const Header = styled.div`
  height: 80px;
  width: 100%;

  h1 {
    color: #58548e;
    font-size: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #ebedef;
  padding-left: 50px;
  padding-top: 30px;
`;

export const Children = styled.div`
  margin: 20px 0px;
  margin-right: 100px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
