import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ScheduledClasses from "./pages/dashboard/ScheduledClasses";
import History from "./pages/dashboard/History";
import VideoCall from "./pages/dashboard/VideoCall";
import MyAccount from "./pages/dashboard/MyAccount";
import MyAgenda from "./pages/dashboard/MyAgenda";

import { store, persistor } from "./store/index";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/scheduledclasses">
              <ScheduledClasses />
            </Route>
            <Route exact path="/history">
              <History />
            </Route>
            <Route exact path="/videocall">
              <VideoCall />
            </Route>
            <Route exact path="/myagenda">
              <MyAgenda />
            </Route>
            <Route exact path="/myaccount">
              <MyAccount />
            </Route>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
