import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { BsPlusCircleFill, BsTrash } from "react-icons/bs";

import { Nav, Input, Button, Select, TextArea } from "../../../components";

import {
  Container,
  ContainerButton,
  HeaderSection,
  ContainerGenerals,
  ContainerInputs,
  ContainerPhoto,
  Row,
  Divider,
  ListMatters,
  HeaderMatters,
  ItemListMatters,
} from "./styles";

import api from "../../../services/api";
import { setUser } from "../../../store/modules/user/actions";

function MyAccount() {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const [photo, setPhoto] = useState();
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);

  const [data, setData] = useState({});
  const [matter, setMatter] = useState("");
  const [listMatters, setListMatters] = useState([]);
  const [loadingBt, setLoadingBt] = useState(false);
  const [loadingBtMatter, setLoadingBtMatter] = useState(false);

  useEffect(() => {
    loadData();
    loadMatters();
  }, []);

  const loadData = () => {
    console.log("user", user);
    setData({ ...user });
    setPhoto(user?.foto);
  };

  const onDrop = useCallback((files) => {
    console.log("onDrop", files[0]);
    setLoadingUploadFile(true);
    fileUpload(files[0])
      .then((response) => {
        console.log("response", response.data);
        setLoadingUploadFile(false);
        setPhoto(response.data);
      })
      .catch((error) => {
        alert("Não foi possivel fazer upload");
        console.log("catch", error);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });

  const fileUpload = (file) => {
    const url = `files`;
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return api.post(url, formData, config);
  };

  const updateData = () => {
    setLoadingBt(true);
    const obj = { ...data };
    obj.foto_id = photo.id;
    api
      .put(`agendamento/professor/${user.id}`, obj)
      .then((resp) => {
        dispatch(setUser(resp.data));
        alert("Perfil atualizado com sucesso");
        console.log("resp updateData", resp.data);
      })
      .catch((error) => {
        console.log("error updateData", error);
        alert(
          error?.response?.data?.error ||
            "Não foi possível concluir sua solicitação. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoadingBt(false);
      });
  };

  const loadMatters = () => {
    api.get(`agendamento/professor/materias/${user.id}`).then((resp) => {
      console.log("loadMatters", resp.data);
      setListMatters(resp.data.rows);
    });
  };

  const registerMatter = () => {
    if (matter.trim().length <= 3) return alert("Informe uma matéria valida");
    setLoadingBtMatter(true);
    api
      .post("agendamento/professor/materias", {
        nome: matter,
      })
      .then((resp) => {
        loadMatters();
        setMatter("");
        alert("Matéria cadastrada com sucesso");
        console.log("resp matter", resp.data);
      })
      .catch((error) => {
        console.log("error matter ", error);
        alert(
          error?.response?.data?.error ||
            "Não foi possível concluir sua solicitação. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoadingBtMatter(false);
      });
  };

  return (
    <Nav title="Minha Conta">
      <Container>
        <HeaderSection>
          <h2>Geral</h2>
        </HeaderSection>

        <ContainerGenerals>
          <ContainerInputs>
            <Row>
              <Input
                label="Nome"
                value={data.nome}
                onChange={(e) => setData({ ...data, nome: e.target.value })}
              />
              <Divider />
              <Input
                label="Idade"
                value={data.idade}
                onChange={(e) => setData({ ...data, idade: e.target.value })}
                type="number"
                maxlength={1}
              />
            </Row>
            <Row>
              <Select
                label="Genero"
                list={[
                  { value: 1, label: "Masculino" },
                  { value: 2, label: "Feminino" },
                ]}
                value={data.genero}
                onChange={(e) => setData({ ...data, genero: parseInt(e) })}
              />
              <Divider />
              <Input
                label="Formação"
                value={data.formacao}
                onChange={(e) => setData({ ...data, formacao: e.target.value })}
              />
            </Row>

            <TextArea
              label="Bio"
              value={data.bio}
              onChange={(e) => setData({ ...data, bio: e.target.value })}
              rows={5}
              cols={50}
              maxlength={200}
            />
          </ContainerInputs>
          <ContainerPhoto>
            <div>
              {loadingUploadFile ? (
                <div className="loader"></div>
              ) : (
                <>
                  {photo ? (
                    <img alt="photo" src={photo.url} />
                  ) : (
                    <img src="https://st3.depositphotos.com/1156795/34887/v/380/depositphotos_348876268-stock-illustration-profile-placeholder-image-gray-silhouette.jpg" />
                  )}
                </>
              )}
            </div>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button>Escolha a Imagem</button>
            </div>
          </ContainerPhoto>
        </ContainerGenerals>

        <HeaderSection>
          <h2>Áreas de conhecimento</h2>
        </HeaderSection>

        <HeaderMatters>
          <Input
            placeholder="Matéria"
            value={matter}
            onChange={(e) => setMatter(e.target.value)}
          />
          {loadingBtMatter ? (
            <div className="loader" />
          ) : (
            <BsPlusCircleFill id="icon" onClick={registerMatter} />
          )}
        </HeaderMatters>

        <ListMatters>
          {listMatters.map((item) => (
            <ItemListMatter item={item} loadList={loadMatters} />
          ))}
        </ListMatters>

        <ContainerButton>
          {loadingBt ? (
            <div className="loader" />
          ) : (
            <Button title="SALVAR" onClick={updateData} />
          )}
        </ContainerButton>
      </Container>
    </Nav>
  );
}

const ItemListMatter = ({ item, loadList = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const deleteMatter = () => {
    setLoading(true);
    api
      .delete(`agendamento/professor/materias/${item.id}`)
      .then((resp) => {
        loadList();
        console.log("deleteMatter", resp.data);
        alert("Matéria deletada com sucesso");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <ItemListMatters key={String(item.id)}>
      <label>{item?.materia?.nome}</label>
      {loading ? (
        <div className="loader" />
      ) : (
        <BsTrash id="icon" onClick={deleteMatter} />
      )}
    </ItemListMatters>
  );
};

export default MyAccount;
