import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import api from "../../../services/api";
import { setUser, setToken } from "../../../store/modules/user/actions";

import { Input } from "../../../components";
import bg_login from "../../../assets/imgs/bg_login.png";
import logo from "../../../assets/imgs/logo-pequena.png";

import {
  Container,
  Content,
  Header,
  ContainerInputs,
  Background,
} from "../styles";

function Login() {
  const dispatch = useDispatch();
  const { user, token } = useSelector((store) => store.user);

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      history.push("scheduledclasses");
    }
  }, [user, token]);

  const loginRequest = () => {
    if (email === "") {
      return alert("informe um email válido.");
    }
    if (password.length < 6) {
      return alert("informe uma senha com no mínimo 6 caracteres.");
    }
    setLoading(true);
    api
      .post("agendamento/professor/auth", {
        email,
        password,
      })
      .then((resp) => {
        console.log("resp login", resp);
        const { token, professor } = resp.data;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        dispatch(setToken(token));
        dispatch(setUser(professor));
      })
      .catch((error) => {
        alert(
          error?.response?.data?.error ||
            "Não foi possível concluir sua solicitação. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Content>
        <Header>
          <div>
            <img src={logo} />
            <h5>CNM Professores</h5>
          </div>
          <button
            onClick={() => {
              history.push("register");
            }}
          >
            CRIAR CONTA
          </button>
        </Header>
        <ContainerInputs>
          <h1>Faça login para ter acesso</h1>
          <p>
            descubra uma comunidade solidária de instrutores online. Obtenha
            acesso instantâneo a todos os cursos
          </p>
          <Input
            label="E-mail"
            type="text"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Input
            label="Senha"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button loading={loading} disabled={loading} onClick={loginRequest}>
            {loading ? <div className="loader" /> : "Entrar"}
          </button>
        </ContainerInputs>
      </Content>
      <Background>
        <img src={bg_login} />
      </Background>
    </Container>
  );
}

export default Login;
