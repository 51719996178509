import React from "react";
import { useSelector } from "react-redux";

import { Nav } from "../../components";

function VideoCall() {
  const { room_link } = useSelector((store) => store.schedule);

  return (
    <Nav title="Chamada de vídeo">
      <iframe
        style={{ height: "100%" }}
        src={room_link}
        allow="camera; microphone; fullscreen; speaker; display-capture"
      />
    </Nav>
  );
}

export default VideoCall;
