import React from "react";

import { Container, Input, Label } from "./styles";

function ComponentInput({
  label,
  type = "text",
  value = "",
  onChange,
  ...props
}) {
  return (
    <Container>
      {label && <Label for="input">{label}</Label>}
      <Input
        type={type}
        id="input"
        value={value}
        onChange={onChange}
        {...props}
      />
    </Container>
  );
}

export default ComponentInput;
