import React from "react";

import { Container, Label, Select } from "./styles";

function SelectComponent({ label, value = "", onChange, list = [], props }) {
  return (
    <Container>
      {label && <Label for="select">{label}</Label>}
      <Select
        id="select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      >
        {list.map((item) => (
          <option value={item?.value}>{item?.label}</option>
        ))}
      </Select>
    </Container>
  );
}

export default SelectComponent;
