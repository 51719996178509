import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  AiOutlineCalendar,
  AiOutlineHistory,
  AiOutlineSchedule,
  AiOutlineLogout,
} from "react-icons/ai";
import { VscAccount } from "react-icons/vsc";
import api from "../../services/api";
import { setUser, setToken } from "../../store/modules/user/actions";

import {
  Container,
  Menu,
  MenuHeader,
  Content,
  Header,
  Children,
  ItemListMenu,
} from "./styles";

import logo from "../../assets/imgs/logo.png";

function Nav({ children, title = "" }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { user, token } = useSelector((store) => store.user);

  useEffect(() => {
    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      history.replace("/");
    }
  }, [user, token]);

  const navigate = (path) => {
    history.push(path);
  };

  const listMenu = [
    {
      name: "Aulas agendadas",
      path: "scheduledclasses",
      Icon: AiOutlineCalendar,
      selected: location?.pathname?.includes("scheduledclasses"),
    },
    {
      name: "Histórico",
      path: "history",
      Icon: AiOutlineHistory,
      selected: location?.pathname?.includes("history"),
    },
    {
      name: "Minha agenda",
      path: "myagenda",
      Icon: AiOutlineSchedule,
      selected: location?.pathname?.includes("myagenda"),
    },
    {
      name: "Minha Conta",
      path: "myaccount",
      Icon: VscAccount,
      selected: location?.pathname?.includes("myaccount"),
    },
    {
      name: "Sair",
      action: () => {
        dispatch(setUser(null));
        dispatch(setToken(null));
      },
      Icon: AiOutlineLogout,
    },
  ];

  return (
    <Container>
      <Menu>
        <MenuHeader>
          <img src={logo} alt="Concursos na Mão" />
        </MenuHeader>
        <ul>
          {listMenu.map(({ action, path, name, Icon, selected }, index) => (
            <ItemListMenu
              key={String(index)}
              onClick={() => {
                if (path) {
                  navigate(path);
                } else {
                  action();
                }
              }}
              selected={selected}
            >
              <Icon id="icon" />
              <label>{name}</label>
            </ItemListMenu>
          ))}
        </ul>
      </Menu>
      <Content>
        <Header>
          <h1>{title}</h1>
        </Header>
        {user && <Children>{children}</Children>}
      </Content>
    </Container>
  );
}

export default Nav;
