import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user", "schedule"],
};

export default (reducers) => {
  const persistedReducer = persistReducer(persistConfig, reducers);
  return persistedReducer;
};
