import styled from "styled-components";

import { colors } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
`;

export const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  color: ${colors.color1};
  margin-left: 3px;
  margin-bottom: 2px;
`;

export const Input = styled.input`
  padding: 0px 10px;
  max-width: 100%;
  font-family: inherit;
  font-size: 16px;
  color: ${colors.inputText};
  &:focus {
    border: ${colors.primary} 1px solid;
    outline: 0;
  }
  border: ${colors.border} 1px solid;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 45px;
`;
