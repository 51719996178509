import styled from "styled-components";

import { colors } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
  background-color: ${colors.primary};
  min-height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 55%;
  min-width: 55%;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  background-color: white;
  padding-top: 57px;
  padding-left: 80px;
  padding-right: 70px;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    background-color: #58548e;
    border-radius: 5px;
    height: 34px;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h5 {
    font-size: 24px;
    color: #58548e;
    margin-left: 10px;
  }
  button {
    width: 112px;
    height: 40px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid ${colors.border};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: ${colors.primary};

    :hover {
      background-color: ${colors.primary};
      color: white;
    }
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  max-width: 450px;

  h1 {
    color: #58548e;
    font-size: 32px;
  }

  p {
    color: #b0aec9;
    font-size: 16px;
  }

  button {
    background-color: ${colors.primary};
    color: white;
    margin-top: 30px;
    width: 450px;
    height: 60px;
    border-radius: 5px;
    border-width: 0px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #615589;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      animation: spin 1s linear infinite;
    }
  }
`;

export const Background = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  img {
    width: 80%;
    object-fit: contain;
  }
`;
