import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Nav } from "../../../components";

import { Container, List, ContainerItemList, TitleDate } from "./styles";

import api from "../../../services/api";
import { setRoomLink } from "../../../store/modules/schedule/actions";

function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((store) => store.user);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    loadList();
  }, []);

  const loadList = () => {
    setLoading(true);
    api
      .get(`agendamento/aula?id_professor=${user.id}`)
      .then((resp) => {
        console.log("data", resp.data);
        setList(resp.data.rows);
      })
      .catch((error) => {
        setList([]);
        console.log("error", error);
        alert("Não foi possível trazer os dados da sua agenda");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Nav title="Aulas agendadas">
      <Container>
        <TitleDate>Segunda Feira</TitleDate>
        <List>
          {list.map((item) => (
            <ItemList
              key={String(item.id)}
              foto={item?.usuario?.foto_id?.url}
              nome={item?.usuario?.nome}
              hora={item.hora + " - " + item.data}
              assunto={item.assunto}
              onClick={() => {
                dispatch(setRoomLink(item.room_link));
                history.push("videocall");
              }}
            />
          ))}
        </List>
      </Container>
    </Nav>
  );
}

const ItemList = ({ foto, nome, hora, assunto, onClick }) => (
  <ContainerItemList>
    <img src={foto} />
    <div>
      <h3>{nome}</h3>
      <label>{hora}</label>
      <label>{assunto}</label>
      <div id="containerButton">
        <button
          style={{ backgroundColor: "#2eb85c", color: "white" }}
          onClick={onClick}
        >
          entrar na sala
        </button>
      </div>
    </div>
  </ContainerItemList>
);

export default Dashboard;
