import produce from "immer";

const INITIALSTATE = {
  room_link: null,
};

export default function reducer(state = INITIALSTATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@schedule/SET_ROOM_LINK": {
        draft.room_link = action.payload.room_link;
        break;
      }
      default:
        return state;
    }
  });
}
