import styled from "styled-components";

import { colors } from "../../../styles";

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: 75vh;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background-color: white;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
`;

export const HeaderSection = styled.div`
  color: #58548e;
  font-size: 20px;
  font-weight: bold;
  border-bottom-color: #edeff6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 10px;
`;

export const ContainerGenerals = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const Divider = styled.div`
  width: 40px;
`;

export const HeaderMatters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;

  #icon {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    color: ${colors.primary};
  }
`;

export const ListMatters = styled.div`
  margin-top: 20px;
  max-width: 95%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const ItemListMatters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${colors.border};
  padding: 10px 10px;
  padding-left: 15px;
  margin-bottom: 10px;
  background-color: green;
  border-radius: 15px;
  margin-right: 15px;
  background-color: #fbe6e8;
  color: #ed596a;
  #icon {
    margin-left: 10px;
  }
  .loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #ed596a;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
    margin-left: 10px;
  }
`;

export const ContainerPhoto = styled.div`
  display: flex;
  width: 240px;
  justify-content: center;
  padding-top: 20px;
  flex-direction: column;
  margin-left: 30px;

  div:first-of-type {
    border: solid 1px ${colors.border};
    width: 150px;
    height: 220px;
    border-radius: 5px;
  }
  img {
    height: 100%;
    width: 100%;
    max-width: 150px;
    max-height: 220px;
    border-radius: 5px;
    object-fit: cover;
  }

  button {
    margin-top: 30px;
    width: 150px;
    height: 46px;
    border: 1px solid ${colors.color1};
    background-color: white;
    border-radius: 5px;
    color: ${colors.color1};
    font-size: 14px;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;
