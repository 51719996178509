import styled, { css } from "styled-components";

import { colors } from "../../styles";

export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
`;

export const TD = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;
export const TH = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: ${colors.primary};
  color: white;
  width: ${(props) => props.small && "20px"};
`;
