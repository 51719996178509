import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Content,
  Header,
  ContainerInputs,
  Background,
} from "../styles";

import { Input, Select } from "../../../components";
import bg_register from "../../../assets/imgs/bg_register.png";
import logo from "../../../assets/imgs/logo-pequena.png";

import api from "../../../services/api";
import { setUser, setToken } from "../../../store/modules/user/actions";

function Register() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, token } = useSelector((store) => store.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      history.push("scheduledclasses");
    }
  }, [user, token]);

  const register = () => {
    if (email === "") {
      return alert("informe um email válido.");
    }
    if (password.length < 6) {
      return alert("informe uma senha com no mínimo 6 caracteres.");
    }
    if (name === "") {
      return alert("informe um nome válido.");
    }
    if (!gender) {
      return alert("informe seu sexo");
    }
    const data = {
      nome: name,
      email,
      genero: parseInt(gender),
      senha: password,
    };
    console.log("data", data);
    setLoading(true);
    api
      .post("agendamento/professor", data)
      .then((resp) => {
        console.log("resp register", resp);
        const { token, professor } = resp.data;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        dispatch(setToken(token));
        dispatch(setUser(professor));
      })
      .catch((error) => {
        alert(
          error?.response?.data?.error ||
            "Não foi possível concluir sua solicitação. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Content>
        <Header>
          <div>
            <img src={logo} />
            <h5>CNM Professores</h5>
          </div>
          <button
            onClick={() => {
              history.push("/");
            }}
          >
            LOGIN
          </button>
        </Header>
        <ContainerInputs>
          <h1>Torne-se um instrutor!</h1>
          <p>
            descubra uma comunidade solidária de instrutores online. Obtenha
            acesso instantâneo a todos os cursos
          </p>

          <Input
            label="Nome"
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="E-mail"
            type="text"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Select
            label="Genero"
            value={gender}
            onChange={(e) => setGender(e)}
            list={[
              { value: 1, label: "Masculino" },
              { value: 2, label: "Feminino" },
            ]}
          />

          <Input
            label="Senha"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button loading={loading} disabled={loading} onClick={register}>
            {loading ? <div className="loader" /> : "Cadastrar"}
          </button>
        </ContainerInputs>
      </Content>
      <Background>
        <img src={bg_register} />
      </Background>
    </Container>
  );
}

export default Register;
