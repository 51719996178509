import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: none;
  flex: 1;
  max-height: 50%;
  padding-bottom: 50px;
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  max-height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  max-height: 55%;
  margin-bottom: 20px;
`;

export const ContainerButtonSave = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  max-height: 60px;
  height: 60px;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.05);

  label:first-of-type {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
  }

  label:last-of-type {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
  }
`;

export const ItemTime = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  max-height: 50px;
  height: 50px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  background-color: white;
  background-color: ${(props) => (props.time ? "#f1f1f1" : "white")};
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const BadgeStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.status === 0
      ? "#FBE6E8"
      : props.status === 1
      ? "#5ECCC5"
      : "#58548e"};
  color: ${(props) =>
    props.status === 0 ? "#ED596A" : props.status === 1 ? "#D4F8F7" : "white"};
  font-size: 12px;
`;
